import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {HeaderSearchInput} from "./components/header-search/HeaderSearchInput";

const target = document.getElementById('header-input-search');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

const appId = target?.dataset?.['appId']!;
const apiKey = target?.dataset?.['apiKey']!;
const productIndex = target?.dataset?.['productIndex']!;
const isAnonymous = target?.dataset?.['isAnonymous'];


if (target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="header-input-search"
			locale={localeCode}
			prefetchTranslations={[
				'frontend.header-search.mobile.popup.cancel',
				'frontend.header-search.results-button.noHits',
				'frontend.header-search.results-button.noTerm',
				'frontend.header-search.results-button.showResults'
			]}>
			<HeaderSearchInput
				appId={appId}
				apiKey={apiKey}
				productIndex={productIndex}
				isAnonymous={isAnonymous === 'true'}
			/>
		</BundleEntry>,
		document.getElementById('header-input-search')
	);
}
