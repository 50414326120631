import {ReactElement, useState} from "react";
import {Configure, InstantSearch} from "react-instantsearch-dom";
import {Transition} from "@headlessui/react";
import {HeaderSearchSearchbox} from "./HeaderSearchSearchbox";
import {HeaderSearchResults} from "./HeaderSearchResults";
import {InsightsClient} from "react-instantsearch-core";
import {HeaderSearchResultsButton} from "./HeaderSearchResultsButton";
import {initAlgolia} from "../../lib/algolia/algoliaClient";
import {useMediaQuery} from "react-responsive";
import {mediaQueries} from "../../lib/responsive-breakpoints";
import {SearchState} from "../advanced-search/AdvancedSearch";
import qs from "qs";
import {pick} from "ramda";
import {qsFormat} from "../../lib/urlParsing";

function searchStateToUrl(searchState: SearchState | null): string {
	const queryString = qs.stringify(
		pick(['query', 'refinementList'], searchState),
		qsFormat.stringify
	);
	return `/search/?${queryString}`;
}

export interface HeaderSearchInputProps {
	appId: string,
	apiKey: string,
	productIndex: string,
	isAnonymous?: boolean
}

export function HeaderSearchInput(props: HeaderSearchInputProps): ReactElement {
	// algolia
	const algolia = initAlgolia(props.appId, props.apiKey);
	const [searchState, setSearchState] = useState<any>({});
	const searchTerm = searchState.query ?? "";

	// all the component logic
	const isMobile = !useMediaQuery(mediaQueries.sm);
	const showResults = searchTerm.length > 0;
	const hitsPerPage = isMobile ? 6 : 15;

	const goToSearch = () => window.location.href = searchStateToUrl(searchState);

	return <div className={"md:tw-hidden tw-w-full"}>
		{<InstantSearch
			searchClient={algolia.client}
			indexName={props.productIndex}
			searchState={searchState}
			onSearchStateChange={setSearchState}
			createURL={searchStateToUrl}
		>
			<Configure
				typoTolerance={true}
				page={0}
				hitsPerPage={hitsPerPage}
				facets={['categories', 'manufacturer']}
				analytics={true}
				clickAnalytics={true}
			/>
			
			<Transition
				show={true}
				unmount={false}
				enter="tw-transition tw-duration-100"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition tw-delay-100 tw-duration-200"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
			>
				<div className="tw-w-full tw-bg-white tw-z-10">

					{/* search textbox*/}
					<Transition
						className="tw-flex tw-w-full"
						show={true}
						unmount={false}
						enter="tw-transition-all tw-duration-300 tw-transform tw-transform-gpu sm:tw-transition-none"
						enterFrom="tw-opacity-0 tw-scale-95 tw--translate-y-8"
						enterTo="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leave="tw-transition-all tw-duration-100 tw-transform tw-transform-gpu"
						leaveFrom="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leaveTo="tw-opacity-0 tw-scale-95 tw--translate-y-8"
						id="search-input"
					>
						<div className="tw-w-full">
							<HeaderSearchSearchbox onSubmit={goToSearch}/>
						</div>
					</Transition>

					{/* dropdown */}
					<Transition
						className="tw-absolute tw-w-full tw-flex-col tw-overflow-y-auto tw-overflow-x-hidden sm:tw-relative tw-overflow-visible tw-bg-white tw-z-50 tw--ml-10"
						show={showResults}
						unmount={true}
						enter="tw-transition-all tw-duration-300 tw-transform"
						enterFrom="tw-opacity-0 tw-scale-95 tw--translate-y-8"
						enterTo="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leave="tw-transition-all tw-duration-200 tw-transform"
						leaveFrom="tw-opacity-100 tw-scale-100 tw-translate-y-0"
						leaveTo="tw-opacity-0 tw-scale-95 tw--translate-y-8"
					>
						<div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-overflow-x-hidden
                                        sm:tw-block sm:tw-absolute sm:tw-top-0 sm:tw--left-24 sm:tw-max-h-searchBox sm:tw-w-searchBox
                                        sm:tw-flex sm:tw-flex-col sm:tw-overflow-hidden
                                        sm:tw-bg-white sm:tw-shadow-drop-down tw-w-full">
							<div
								className="tw-flex-1 tw-inset-0 tw-overflow-y-auto tw-overflow-x-hidden tw-relative tw-w-full">
								<HeaderSearchResults
									productIndex={props.productIndex}
									insightsClient={algolia.searchInsights as InsightsClient}
									searchTerm={searchTerm}
								/>
							</div>

							{/* go to search button */}
							<div className="tw-flex-none tw-shadow-top-white">
								<HeaderSearchResultsButton
									searchTerm={searchTerm}
									onClick={goToSearch}
								/>
							</div>
						</div>
					</Transition>
				</div>
			</Transition>
		</InstantSearch>}
	</div>
}